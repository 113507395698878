<template>
	<div data-route>
		<page-header
			heading="Course focuses"
			:action="getAction"
		/>
		<div data-element="main">
			<table-view
				v-if="focuses"
				:table="getTable"
				:showSearch="false"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';

	export default {
		metaInfo: {
			title: 'Course focuses'
		},
		components: {
			PageHeader,
			TableView
		},
		data: () => ({
			focuses: []
		}),
		computed: {
			getTable () {
				const headings = [
					'Focus'
				];
				if (!this.getFocuses) {
					return {
						headings
					};
				}
				return {
					headings,
					rows: this.getFocuses.map((focus) => {
						return {
							route: `/admin/focuses/${focus.id}/`,
							columns: [
								focus.title
							]
						};
					})
				};
			},
			getFocuses () {
				if (!this.focuses ||
						!this.focuses.length) {
					return false;
				}
				return this.focuses;
			},
			getAction () {
				return {
					text: 'Add new focus',
					route: '/admin/focuses/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/focuses',
						text: 'Focuses'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setFocuses();
		},
		methods: {
			async setFocuses () {
				const focuses = await api.admin.getAllFocuses();
				if (!focuses) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.focuses = focuses;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
